<template>
    <div class="modal profile_modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom px-5">
                <h1>Activity Feed</h1>
                <button class="close_btn" @click="closeModel()"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body" :style="`pointer-events: ${activityLogLoader ? 'none' : ''}`">
                <div class="profile_lifecycles">
                    <div class="filter_sidebar">
                        <ul>
                            <li class="all">
                                <label for="all" class="checkbox">
                                    <input type="checkbox" id="all" v-model="selectAllModules" @click="toggleSelectAll" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                </label> All
                            </li>
                            <li v-for="(moduleList, m) in modules" :key="m">
                                <label :for="`module-${m}`" class="checkbox">
                                    <input type="checkbox" :id="`module-${m}`" @click="handleSelectedModule(moduleList.module)" :checked="checkConfirmation(moduleList.module)" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                </label> {{ moduleList.module }}
                            </li>
                        </ul>
                    </div>
                    <div class="filter_content">
                        <div class="header">
                            <h3>{{ params.duration }}</h3>
                            <date-filter v-model="params" />
                            <button type="button" class="filter_toggle" @click="toggleSidebar"><img src="@/assets/images/list.svg" alt=""></button>
                        </div>
                        <ul class="activity">
                            <div class="infinite-wrapper">
                                <li v-for="(notification, n) in notifications" :key="n">
                                    <h4>{{ notification.module }}
                                        <!-- <label class="tag success">Created</label> -->
                                        <span class="time"> {{ `${moment.utc(notification.created_at).local().format('ll | LT')}` }}</span>
                                    </h4>
                                    <div class="info">
                                        <span v-html="notification.description"></span>
                                    </div>
                                </li>
                                <infinite-loading class="infinite-loading" @infinite="handleNotification" target=".infinite-wrapper" :distance="4" :identifier="resetInfiniteWatcher" />
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const DateFilter = defineAsyncComponent(() => import('@/components/DateFilter'))

    import moment from 'moment'
    import InfiniteLoading from 'v3-infinite-loading'

    export default {
        Name: 'Activity Feed',

        data () {
            return {
                moment,
                params: {
                    page: 1,
                    last_page: 1,
                    modules: [],
                    duration: 'Last 7 Days',
                    start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                },
                selectAllModules: false,
                allModules: [],
                notifications: [],
                state: undefined,
                loader: false,
                resetInfiniteWatcher: 0,
            }
        },

        props: {
            modelValue: Boolean,
        },

        emit: ['update:modelValue'],

        components: {
            DateFilter,
            InfiniteLoading
        },

        watch: {
            modelValue: {
                handler (value) {
                    const vm = this;

                    if (value) {
                        setTimeout(function () {
                            vm.getActivityModule();
                        }, 1000);
                    }
                },
                immediate: true,
            },

            modules (modules) {
                const vm = this;

                if (modules.length) {
                    modules.forEach((module) => {
                        vm.params.modules.push(module.module);
                    });
                }

                if (vm.params.modules.length) {
                    vm.selectAllModules = true;
                    vm.allModules = vm.params.modules;
                    vm.resetInfiniteWatcher++;

                    vm.handleNotification(undefined, true);
                }
            },

            'params.start_date' () {
                const vm = this;

                vm.notifications    = [];
                vm.params.page      = 1;
                vm.params.last_page = 1;
                vm.resetInfiniteWatcher++;
                vm.handleNotification(undefined, true);
            },
        },

        computed: mapState ({
            activityLogLoader: state => state.activityLogModule.activityLogLoader,
            modules: state => state.activityLogModule.modules,
        }),

        mounted () {
            const vm = this;

            if (vm.modules.length) {
                vm.modules.forEach((module) => {
                    vm.params.modules.push(module.module);
                });

                if (vm.params.modules.length) {
                    vm.selectAllModules = true;
                    vm.allModules = vm.params.modules;
                }
            }
        },

        methods: {
            ...mapActions ({
                getActivityModule: 'activityLogModule/getActivityModule',
                getNotification: 'activityLogModule/getNotification',
            }),

            closeModel () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSelectedModule (module) {
                const vm = this;
                var index = vm.params.modules.indexOf(module);

                if (index > -1) {
                    vm.params.modules = vm.params.modules.filter((result) => {
                        return result !== module;
                    });
                } else {
                    vm.params.modules.push(module);
                }

                vm.notifications = [];
                if (vm.params.modules.length) {
                    vm.params.page = 1;
                    vm.params.last_page = 1;
                    vm.resetInfiniteWatcher++;
                    vm.handleNotification(undefined, true);
                }

                if (vm.params.modules.length == vm.allModules.length) {
                    vm.selectAllModules = true;
                } else {
                    vm.selectAllModules = false;
                }
            },

            toggleSelectAll () {
                const vm = this;

                vm.selectAllModules = !vm.selectAllModules;

                if (vm.selectAllModules) {
                    vm.params.modules = vm.allModules;
                    vm.params.page = 1;
                    vm.params.last_page = 1;
                    vm.resetInfiniteWatcher++;
                    vm.handleNotification(undefined, true);
                } else {
                    vm.params.modules = [];
                    vm.notifications = [];
                }
            },

            checkConfirmation (val) {
                const vm = this;
                return vm.params.modules.includes(val);
            },

            handleNotification ($state, force = false) {
                const vm = this;

                if ($state) {
                    vm.state = $state;
                }

                if (((vm.params.page <= vm.params.last_page ) || force) && (vm.params.modules && vm.params.modules.length)) {
                    if (!vm.loader) {
                        vm.loader = true

                        vm.getNotification(vm.params).then((result) => {
                            if (result.data.length) {
                                vm.params.last_page = result.last_page;
                                vm.params.page = result.current_page + 1;

                                vm.notifications.push(...result.data);
                                vm.loader = false;

                                setTimeout(function () {
                                    if (vm.state != undefined) {
                                        vm.state.loaded();
                                    }
                                }, 1000);
                            } else {
                                if (vm.state != undefined) {
                                    vm.state.complete();
                                }

                                vm.loader = false;
                            }
                        });
                    }
                } else {
                    if (vm.state != undefined) {
                        vm.state.complete();
                    }
                }
            },
            toggleSidebar(){
                const ls_bar = document.querySelector('.filter_sidebar');
                if(ls_bar.classList.contains('show')){
                    ls_bar.classList.remove('show');
                }else{
                    ls_bar.classList.add('show');
                }
            },
        }
    }
</script>

<style scoped>
    .modal.profile_modal .modal_container{
        max-height: 700px;
        height: calc(100% - 30px);
        margin: 15px;
    }
    .profile_lifecycles{
        display: flex;
        flex: 1;
        padding: 0;
        position: relative;
    }
    .profile_lifecycles .filter_sidebar{
        padding: 0;
        width: 240px;
        border-right: 1px solid #eee;
        padding-bottom: 10px;
        position: relative;
        z-index: 2;
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.07);
        max-height: 638px;
        overflow-y: overlay;
    }
    .profile_lifecycles .filter_sidebar::-webkit-scrollbar {
        width: 4px;
    }
    .profile_lifecycles .filter_sidebar::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 2px;
    }
    .profile_lifecycles .filter_sidebar li{
        display: flex;
        align-items: center;
        padding:10px 30px;
        font-size: 14px;
        line-height: 18px;
        border-bottom: 1px solid #f5f5f5;
    }
    .profile_lifecycles .filter_sidebar li.all{
        background: #ebebeb;
    }
    .profile_lifecycles .filter_sidebar li:last-child{
        border: 0;
    }
    .profile_lifecycles .filter_sidebar li .checkbox{
        margin-right: 10px;
    }
    .profile_lifecycles .filter_content{
        flex: 1;
    }
    .profile_lifecycles .filter_content .activity{
        height: calc(100% - 69px);
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .profile_lifecycles .filter_content .activity::-webkit-scrollbar {
        display: none;
    }
    .profile_lifecycles .filter_content .activity li{
        padding-bottom: 25px;
    }
    .profile_lifecycles .filter_content .activity li h4{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: center;
    }
    .profile_lifecycles .filter_content .activity li h4 .tag{
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }
    .profile_lifecycles .filter_content .activity li h4 .tag.success{
        background: #00aa14;
    }
    .profile_lifecycles .filter_content .activity li h4 .tag.failed{
        background: #f04438;
    }
    .profile_lifecycles .filter_content .activity li h4 span{
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-left: 7px;
    }
    .profile_lifecycles .filter_content .activity li .info{
        padding: 10px 15px;
        border-radius: 6px;
        background: #f1f1f1;
        margin: 8px 0 0 0;
        font-size: 12px;
        line-height: 17px;
        font-weight: 500;
        color: #32373b;
        display: inline-block;
    }
    .profile_lifecycles .filter_content .header{
        display: flex;
        padding: 20px 40px;
        border-bottom: 1px solid #eaeaea;
        position: relative;
    }
    .profile_lifecycles .filter_content .header h3{
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: flex-end;
        margin-right: auto;
    }
    .profile_lifecycles .custom_range{
        right: 40px;
    }
    .infinite-wrapper {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 20px 40px;
    }
    .infinite-wrapper::-webkit-scrollbar{
        width: 4px;
    }
    .infinite-wrapper::-webkit-scrollbar-thumb{
        border-radius: 2px;
        background: #dbdbdb;
    }
    .filter_toggle{
        width: 30px;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        padding: 6px;
        line-height: 0;
        background: #fff;
        margin: 0 0 0 8px;
        cursor: pointer;
        display: none;
    }
    @media(max-width: 767px){
        .infinite-wrapper{
            padding: 20px 20px 20px 30px;
        }
        .profile_lifecycles .filter_content .header{
            padding: 20px 25px 20px 30px;
        }
        .profile_lifecycles .filter_sidebar{
            position: absolute;
            height: 100%;
            left: -240px;
            top: 0;
            z-index: 1;
            background: #fff;
            transition: all 0.3s ease-in-out;
            border-radius: 0 0 0 12px;
        }
        .profile_lifecycles .filter_sidebar.show{
            left: 0;
        }
        .filter_toggle{
            display: block;
        }
    }
    @media(max-width: 499px){
        .profile_lifecycles .filter_content .header h3{
            font-size: 16px;
            line-height: 25px;
        }
        .profile_lifecycles .filter_content :deep(.form_grp .multiselect){
            min-height: 25px;
        }
        .profile_lifecycles .filter_content .activity li h4{
            font-size: 15px;
            line-height: 20px;
        }
        .profile_lifecycles .filter_content .activity li h4 span{
            font-size: 10px;
        }
        .profile_lifecycles .filter_content .activity li .info{
            font-size: 11px;
            line-height: 15px;
            padding: 8px 12px;
        }
    }
</style>
